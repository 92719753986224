<template>
  <div class="my-second card bgcolor">
      <Head :title="$t('info.set_card')" :show="true"  ></Head>
      <div class="common-box card-box">
          <div class="addcard-main clearfix">
                 <van-form class="addcard-form" ref="addform" @submit="addCard">
                        <van-cell-group class="form-box ">
                            <van-field
                                readonly
                                clickable
                                name="bankname"
                                v-model="obj.bankname "
                                :label="$t('info.banks')"
                                :rules="[{ required: true, message:$t('login.ptian')}]"
                                @click="showPicker = true"
                            >
                                <template #extra >
                                    <div class="choosebank">
                                        <span >{{$t('info.choose_bank')}}</span>
                                        <van-icon name="arrow"></van-icon>
                                    </div>
                                </template>
                            </van-field>
                             <van-field
                                v-model="obj.bankaccount"
                                type="text"
                                name="bankaccount"
                                :label="$t('info.bank_account')"
                                :placeholder="$t('info.enter_bank_account')"
                                :rules="[{ required: true, message:$t('login.ptian')}]"
                                
                            />
                            <van-field
                                v-model="obj.bankusername"
                                type="text"
                                name="bankusername"
                                :label="$t('info.carder')"
                                :placeholder="$t('info.input_name')"
                                :rules="[{ required: true, message:$t('login.ptian')}]"
                                
                            />
                            <van-field
                                 @input = "cardNumFormat"
                                v-model="obj.banknum"
                                type="text"
                                name="banknum"
                                :label="$t('info.card_nums')"
                                :placeholder="$t('info.input_card_nums')"
                                class="cell-first"
                                :rules="[{ required: true, message:$t('login.ptian')}]"

                            />
                            <van-field
                                v-model="obj.expired_at"
                                type="text"
                                name="expired_at"
                                :label="$t('info.data_before')"
                                placeholder=""
                                :rules="[{ required: true, message:$t('login.ptian')}]"
                                 readonly
                                clickable
                                @click="showtime=true"
                                right-icon="arrow"
                            />
                            <van-field
                                v-model="obj.idcard_num"
                                type="text"
                                name="idcard_num"
                                :label="$t('info.id_card')"
                                :placeholder="$t('info.input_id_card')"
                               :rules="[{ required: true, message:$t('login.ptian')}]"
                            />
                            <van-field
                                v-model="obj.area"
                                type="text"
                                name="area"
                                :label="$t('info.c_area')"
                                right-icon="arrow"
                                readonly
                                class="van-nohidden van-countrynum"
                                @click.stop="showSome"
                            >   
                                <template #extra >
                                    
                                </template>
                                <template #input>
                                    <div class="tel-qu width100">
                                        <AreaNum ref="choosenum" @excountry="getCountryItem"  :country-code="obj.area_code"/>
                                        <span class="my-country">{{obj.country_name}}</span>
                                    </div>
                                </template>
                                
                            </van-field>
                            <van-field
                                v-model="obj.phone"
                                type="text"
                                name="phone"
                                :label="$t('info.live_phone')"
                                :placeholder="$t('info.input_phone_num')"
                               :rules="[{ required: true, message:$t('login.ptian')}]"
                                class="cell-last"
                            />
                            <van-field
                                v-model="obj.code"
                                type="text"
                                name="code"
                                :label="$t('info.phone_card')"
                                :placeholder="$t('login.enter')+(lang=='en'?' ':'')+$t('login.captcha')"
                                :rules="[{ required: true, message:$t('login.required_captcha')}]"
                                maxlength="6"
                            >
                                <template #button>
                                    <SmsDemo :phone="obj.phone" type="5"  :area="obj.area" ></SmsDemo>
                                </template>
                            </van-field>
                        </van-cell-group>
                </van-form>
          </div>
          <van-button class="confirm-btn" @click="checkform">{{$t('info.change')}}</van-button>
      </div>

      <van-popup class="pick-popup" :style="{ height: '8rem' }" v-model="showPicker" position="bottom" duration="0.3">
        <van-picker
        :title="$t('info.choose_bank')"
        show-toolbar
        :confirm-button-text="$t('info.complate')"
        :columns="columns"
        value-key="name"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        :default-index="0"
        />
    </van-popup>
     <!-- 选择年月 -->
    <van-popup v-model="showtime" class="time-popup" position="bottom"   duration="0.2" >
        <van-datetime-picker
            v-model="currentDate"
            type="year-month"
            class="time-picker"
            @cancel="showtime=false"
            @confirm="chooseDate"
            :formatter="formatter"
        />
    </van-popup>
  </div>
</template>


<script>
import {areaCode} from "@/common"
import {Notify} from "vant"
import {mapState,mapMutations} from 'vuex'
import countries from '@/components/area/countryList'
export default {
    data(){
        return {
            lang:localStorage.getItem("langData") || 'th',
            showtime:false,
            currentDate: new Date(),
            showPicker:false,
            obj:{
                area:"66",
                ...areaCode
            },
            // 需要处理
            // columns:["开泰银行",'建设银行',"盘古银行","中国银行","泰国银行"]
            columns:[]
            
        }
    },
    computed:{
        ...mapState(['cardList']),
        // detail(){
        //     return this.cardList.find(item=>item.id==this.$route.query.id);
        // }
    },
    mounted(){
        this.$ajax.getBankCategoryList()
        .then(res=>{
            if(res.code==200){
                this.columns = res.data
            }
        })
        if(!this.cardList.length>0){
            this.$ajax.banklist()
            .then(res=>{
                if(res.code==200){
                    this.setCardList(res.data)
                    var detail = res.data.find(item=>item.id==this.$route.query.id);
                    // var country_name = this.obj.country_name
                    this.obj = {...this.obj,...detail}
                    this.getCountry(this.obj.area)
                }
            })
        }else{
            var detail = this.cardList.find(item=>item.id==this.$route.query.id);
            // var country_name = this.obj.country_name
            this.obj = {...this.obj,...detail}
            this.getCountry(this.obj.area)
        }
    },
    methods:{
        getCountry(area){
            for(var i in countries){
                var value = countries[i];
                if(value.dialCode==area){
                    this.obj = {...this.obj,country_name:value.name,area_code:value.code}
                }
            }
        },
        ...mapMutations(['setCardList']),
        cardNumFormat(){
            var reg = /\S{5}/;
            var v = this.obj.banknum;
            if(reg.test(v)){
                this.obj.banknum = v.replace(/\s/g, '').replace(/(.{4})/g, "$1 ");
            }
        },
        getCountryItem(data){
            this.obj.area = data.dialCode;
            this.obj.country_name = data.name;
            // this.obj.enname = data.code;
        },
        checkform(){
            this.$refs.addform.submit();
        },
        onConfirm(value){
            this.showPicker = false;
            this.obj.bankname = value.name
            this.obj.bankid = value.id;
        },
        showSome(){
            this.$refs.choosenum.hideSubMenu = false;   // 显示国旗 
        },
        // 修改银行卡
        addCard(value){
            this.$ajax.updatebank({
                ...this.obj,
                id:this.$route.query.id
            })
            .then(res=>{
                if(res.code==200){
                    Notify({ type: 'success', message: res.msg ,duration:1200});
                    this.$router.go(-1);
                }
            })
        },
        chooseDate(value){
            this.showtime = false;
            this.year = new Date(value).getFullYear();
            this.month = new Date(value).getMonth() + 1;
            this.month = this.month < 10 ? '0'+this.month : this.month;
            this.obj.expired_at = this.year+"/"+this.month;
        },
        formatter(type, val) {
            if (type === 'year') {
                return `${val}`;
            } else if (type === 'month') {
                return `${val}`;
            }
        return val;
        }
    }
}
</script>
